import {setCookie, deleteCookie} from '~hooks/useCookie'
import {fetchWrapper, convertManagerData} from '~helpers/functions'
import {UserCookies} from '~helpers/constants'

export async function login(data, ctx = null, options = {}) {
	const response = await createToken(data, ctx, options)

	if (response['success']) {
		//Сохраняем данные о токенах из заголовков
		UserCookies['tokens'].forEach((key) => {
			if (response['headers']?.[key]) {
				setCookie(key, response['headers'][key], {
					...(ctx && {req: ctx['req'], res: ctx['res']}),
					...{path: '/'},
				})
			}
		})

		if (response['body']) {
			const value = typeof response['body'] === 'object' ? JSON.stringify(convertManagerData(response['body'])) : response['body']

			setCookie(UserCookies['profile'], value, {
				...(ctx && {req: ctx['req'], res: ctx['res']}),
				...{path: '/'},
			})
		}
	}

	return response
}

export async function logout(reload = true, ctx = null) {
	;[...UserCookies['tokens'], ...[UserCookies['profile']]].forEach((key) => {
		deleteCookie(key, {
			...(ctx && {req: ctx['req'], res: ctx['res']}),
			...{path: '/'},
		})
	})

	if (!ctx && reload && typeof window !== 'undefined') {
		window.location.reload()
	} else {
		return true
	}
}

export async function createToken(data, ctx = null, options = {}) {
	return await fetchWrapper(
		process.env.NEXT_PUBLIC_API_URL + '/auth/api/admin/v1/profile/login',
		ctx,
		{
			body: data,
			headers: {'Content-Type': 'application/json'},
			method: 'POST',
			...options,
		},
		true
	)
}

export async function refreshToken(data, ctx = null, options = {}) {
	return await fetchWrapper(
		process.env.NEXT_PUBLIC_API_URL + '/auth/api/admin/v1/profile/refresh',
		ctx,
		{
			body: data,
			headers: {'Content-Type': 'application/json'},
			method: 'POST',
			...options,
		},
		true
	)
}

//TODO: дополнить урл как бэкенд будет готов
export async function resetPassword(data, ctx, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/auth/api/admin/v1/profile/forgot-password', ctx, {
		body: data,
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	})
}

export async function confirmPassword(data, token, ctx, options = {}) {
	return await fetchWrapper(process.env.NEXT_PUBLIC_API_URL + '/auth/api/admin/v1/profile/set-password/' + token, ctx, {
		body: data,
		headers: {'Content-Type': 'application/json'},
		method: 'POST',
		...options,
	})
}
