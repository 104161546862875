import {refreshToken} from '~actions/auth'

import {deleteCookie, getCookies, setCookie} from '~hooks/useCookie'
import {dayjs} from '~hooks/useDate'
import {UserCookies} from '~helpers/constants'
import {ManagerInterface} from '~types/managers'

export {nanoid as randomId} from 'nanoid'

export const fetchWrapper = async (uri, ctx = null, options = {}, skip_refresh = false) => {
	let response
	let headers

	const cookies = getCookies(ctx && {req: ctx['req'], res: ctx['res']})

	const logoutUser = (ctx) => {
		;[...UserCookies['tokens'], ...[UserCookies['profile']]].forEach((key) => {
			deleteCookie(key, {
				...(ctx && {req: ctx['req'], res: ctx['res']}),
				...{path: '/'},
			})
		})

		if (typeof window !== 'undefined') {
			window.location.reload()
		}
	}

	if ([...UserCookies['tokens'], ...[UserCookies['profile']]].every((key) => Object.keys(cookies).includes(key))) {
		if (!skip_refresh && Number(cookies['access-token-expired-at']) - dayjs().unix() < 60) {
			response = await refreshToken(
				{
					refresh_token: cookies['refresh-token'],
				},
				ctx
			)

			if (response['success']) {
				UserCookies['tokens'].forEach((key) => {
					if (response['headers']?.[key]) {
						cookies[key] = typeof response['headers'][key] === 'object' ? JSON.stringify(response['headers'][key]) : response['headers'][key]

						setCookie(key, cookies[key], {
							...(ctx && {req: ctx['req'], res: ctx['res']}),
							...{path: '/'},
						})
					}
				})

				if (response['body']) {
					cookies[UserCookies['profile']] = typeof response['body'] === 'object' ? JSON.stringify(convertManagerData(response['body'])) : response['body']

					setCookie(UserCookies['profile'], cookies[UserCookies['profile']], {
						...(ctx && {req: ctx['req'], res: ctx['res']}),
						...{path: '/'},
					})
				}

				/* 
				...(key == 'access_token' && response['body']?.['access_token_expire_at'] && {
					expires: dayjs(response['body']['access_token_expire_at']).toDate()
				}),
				*/
			} else {
				logoutUser(ctx)
			}
		}
	} else {
		if ([...UserCookies['tokens'], ...[UserCookies['profile']]].some((key) => Object.keys(cookies).includes(key))) {
			logoutUser(ctx)
		}
	}

	if (!options['headers']) {
		options['headers'] = {}
	}

	if (cookies?.['access-token']) {
		options['headers']['Authorization'] = 'Bearer ' + cookies['access-token']
	}

	if (options?.['body'] && options['headers']?.['Content-Type'] && options['headers']['Content-Type'] == 'application/json') {
		Object.entries(options['body']).map(([key, value]) => {
			//TODO: мб нужна еще вот такая штука - options['body'][key] === '' ||
			if ([undefined, null].includes(options['body'][key])) {
				delete options['body'][key]
			}
		})

		options['body'] = JSON.stringify(options['body'])
	}

	try {
		await fetch(uri, options).then((data) => {
			response = data

			//hack for accessing headers from fetch
			for (const [key, value] of response.headers.entries()) {
				response['headers'][key] = value
			}
		})
	} catch (error) {
		console.error(error)

		response = false
	}

	//TODO: Мб добавить здесь обработку 401?

	return await responseWrapper(response)
}

export const fetchQuery = (params = {}) => {
	let query = ''

	Object.entries(params).forEach(([key, value]) => {
		if (value && String(value).length > 0) {
			query += (query ? '&' : '?') + key + '=' + value
		}
	})

	return query
}

export const responseWrapper = async (response) => {
	let data

	try {
		if (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
			data = await response.blob()
		} else {
			data = await response.json()
		}
	} catch (error) {
		data = {}
	}

	if (response && response.ok) {
		return {
			body: data,
			headers: response.headers,
			status: response.status,
			success: true,
		}
	} else {
		if (data?.error) {
			console.error(data['error'])
		}

		return {
			//TODO: add localization
			body: {
				...data,
				...(!data?.error && {error: 'Something went wrong. Please, repeat later.'}),
			},
			headers: {},
			status: response.status,
			success: false,
		}
	}
}

export const localeReplace = (string, keys, values) => {
	if (typeof keys !== 'object') {
		keys = [keys]
	}

	keys.map((key, index) => {
		string = string.replaceAll('{{ ' + key + ' }}', typeof values === 'object' ? values[index] : values)
	})

	return string
}

export function declension(number, words) {
	const cases = [2, 0, 1, 1, 1, 2]

	number = parseInt(number)

	return words[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export const truncateString = (string = '', beforeSymbols = 0, afterSymbols = 0, soft = false) => {
	if (string.length > beforeSymbols + afterSymbols) {
		//Обрезаем до блжижайшего пробела
		if (soft) {
			let temporaryString
			let indexOfSpace

			if (beforeSymbols > 0) {
				temporaryString = string.slice(beforeSymbols)

				indexOfSpace = temporaryString.indexOf(' ')

				if (indexOfSpace > -1) {
					beforeSymbols += indexOfSpace
				} else {
					beforeSymbols += temporaryString.length - 1
				}
			}
			/*
			if (afterSymbols > 0) {
				//TODO	
			}
			*/
		}

		return (beforeSymbols > 0 ? string.substring(0, beforeSymbols) : '') + '...' + (afterSymbols > 0 ? string.substring(string.length - afterSymbols, string.length) : '')
	} else {
		return string
	}
}

export const makeTreeFromArray = (array = [], id_key = 'id', parent_key = 'parent_id', childrens_array_key = 'childrens') => {
	const map = {}
	const tree = []

	array.forEach((item) => {
		map[item[id_key]] = {
			...item,
		}
		map[item[id_key]][childrens_array_key] = []
	})

	array.forEach((item) => {
		if (item?.[parent_key]) {
			map[item[parent_key]][childrens_array_key].push(map[item[id_key]])
		} else {
			tree.push(map[item[id_key]])
		}
	})

	return tree
}

export const formatSecondsToString = (seconds: number): string => {
	if (!seconds) return ''
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)

	const formattedHours = String(hours).padStart(2, '0')
	const formattedMinutes = String(minutes).padStart(2, '0')

	return formattedHours + ':' + formattedMinutes
}

export const swapArrayItems = function (array, firstIndex, secondIndex): any[] {
	let temp = array[firstIndex]

	array[firstIndex] = array[secondIndex]
	array[secondIndex] = temp

	return array
}

export const makeDeeplink = function (id, kind) {
	return (
		process.env.NEXT_PUBLIC_DEEPLINK_HOST +
		'/?link=' +
		process.env.NEXT_PUBLIC_DEEPLINK_HOST +
		'/' +
		kind +
		'/' +
		id +
		'&apn=' +
		process.env.NEXT_PUBLIC_DEEPLINK_ANDROID_BUNDLE_ID +
		'&isi=' +
		process.env.NEXT_PUBLIC_DEEPLINK_ISI_BUNDLE_ID +
		'&ibi=' +
		process.env.NEXT_PUBLIC_DEEPLINK_IOS_BUNDLE_ID
	)
}

export const clearOrderNumber = function (query) {
	let new_query

	if (query && !query.split('').every((char) => char === '0')) {
		let i = 0

		new_query = query

		while (i < query.length) {
			if (query[i] == '0') {
				new_query = new_query.substring(1)
			} else {
				break
			}

			i = i + 1
		}
	}

	return new_query ? new_query : query
}

export async function getImageAspectRatio(imageUrl) {
	return new Promise((resolve, reject) => {
		const img = new Image()
		img.onload = () => {
			const width = img.naturalWidth
			const height = img.naturalHeight

			let aspectRatio

			if (width > height) {
				aspectRatio = width / height
			} else {
				aspectRatio = height / width
			}
			resolve(aspectRatio)
		}
		img.onerror = (error) => {
			reject(error)
		}
		img.src = imageUrl
	})
}

export const convertManagerData = (data: ManagerInterface) => {
	return {
		uuid: data?.['uuid'],
		roles: data?.['roles'],
		name: data?.['name'],
		surname: data?.['surname'],
		email: data?.['email'],
	}
}
