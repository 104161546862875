import {APP_PERMISSIONS, APP_PERMISSIONS_ACCESSES} from '~types/_app'

export const UserCookies = {
	profile: 'user',
	tokens: ['access-token', 'access-token-expired-at', 'refresh-token'],
}

export const AppLanguages = {
	en: {
		name: 'English',
	},
	ru: {
		name: 'Русский',
	},
}

export const OrderStatuses = {
	success: {
		className: 'ui-tag-green',
		locale: 'SUCCESS',
		value: 'success',
	},
	reject: {
		className: 'ui-tag-red',
		locale: 'REJECT',
		value: 'reject',
	},
	pending: {
		className: 'ui-tag-lightgrey',
		locale: 'PENDING',
		value: 'pending',
	},
	cancelled: {
		className: 'ui-tag-red',
		locale: 'CANCEL',
		value: 'cancelled',
	},
}

export const TransactionStatuses = {
	success: {
		className: 'ui-tag-green',
		locale: 'SUCCESS',
	},
	cancel: {
		className: 'ui-tag-red',
		locale: 'CANCEL',
	},
	pending: {
		className: 'ui-tag-lightgrey',
		locale: 'PENDING',
	},
}

export const RoleTitles = {
	user: {
		locale: 'USER',
	},
	mobile_admin: {
		locale: 'ADMIN',
	},
	mobile_superuser: {
		locale: 'SUPERUSER',
	},
	mobile_seller: {
		locale: 'SELLER',
	},
}
