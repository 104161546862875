import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {AppState} from '~states/App'
import {ModalState} from '~states/Modal'
import {LocaleState} from '~states/Locale'

import {supportSchema} from '~schemas/_app'

import {schema} from '~hooks/useForm'

import {Overlay} from '~ui/Modules'

type FormData = schema.infer<typeof supportSchema>

const AppModals = () => {
	const locale = useAtomValue(LocaleState)
	const [modal, setModal] = useAtom(ModalState)
	const setApp = useSetAtom(AppState)

	return <></>
}

export default AppModals
