import {Fragment, useMemo, memo} from 'react'

import {useRouter} from 'next/router'

import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {AppState} from '~states/App'
import {LocaleState} from '~states/Locale'
import {UserState} from '~states/User'

import {logout} from '~actions/auth'

import {setCookie} from '~hooks/useCookie'

import {Icon, Link} from '~ui/Modules'

import {APP_PERMISSIONS, APP_PERMISSIONS_ACCESSES, AppPermissionsType} from '~types/_app'
import type {SetStateAction} from 'jotai'

interface Props {
	className?: string
	setOpen: (update?: SetStateAction<string>) => void
}

interface MobileMenuNavigationItemInterface {
	thread_id: string
	menutitle: string
	route?: string
	icon?: string
	permissions?: any[]
	onClick?: (e: any) => void
	className?: string
	childrens?: MobileMenuNavigationItemInterface[]
}

interface MobileMenuListItemProps {
	data: MobileMenuNavigationItemInterface
	level?: number
	openedThreads?: string[]
	setOpen: (update?: SetStateAction<string>) => void
	onThreadToggle: (string) => void
	userPermissions: string[]
}

const MobileMenuSidebar = ({setOpen}: Props) => {
	const [app, setApp] = useAtom(AppState)
	const locale = useAtomValue(LocaleState)
	const user = useAtomValue(UserState)

	//TODO: проверить ограничение прав (возможно, не работают)
	//TODO: добавить для раздела массив всех прав, которые используются в треде
	const userPermissions = useMemo<any[]>(() => user['profile']?.['roles'] || [], [user])
	const navigation = [
		{
			thread_id: 'settings',
			icon: '#icon-sliders',
			menutitle: locale['SETTINGS']['MENUTITLE'],
			route: '/settings',
			permissions: ['mobile_admin', 'mobile_superuser'],
			childrens: [
				{
					menutitle: locale['GAMES_ORGANIZATIONS']['MENUTITLE'],
					route: '/settings/organizations/',
				},
				{
					menutitle: locale['SETTINGS_POLYLOG_ORGANIZERS']['MENUTITLE'],
					route: '/settings/polylog-organizers/',
				},
				{
					menutitle: locale['TRIGGERS']['MENUTITLE'],
					route: '/settings/triggers/',
				},
				{
					menutitle: locale['SETTINGS_ACHIEVEMENTS']['MENUTITLE'],
					route: '/settings/achievements/',
				},
				{
					menutitle: locale['SETTINGS_TAGS']['MENUTITLE'],
					route: '/settings/tags/',
				},
			],
		},
		{
			thread_id: 'reviews',
			icon: '#icon-comment',
			menutitle: locale['USERS_REVIEWS']['MENUTITLE'],
			route: '/reviews',
			permissions: ['mobile_admin', 'mobile_superuser'],
			childrens: [
				{
					menutitle: locale['REVIEWS_EXPONENT']['MENUTITLE'],
					route: '/reviews/exponents',
				},
				{
					menutitle: locale['REVIEWS_EMPLOYEE']['MENUTITLE'],
					route: '/reviews/employees',
				},
				{
					menutitle: locale['REVIEWS_MOBILE']['MENUTITLE'],
					route: '/reviews/mobile',
				},
			],
		},
		{
			thread_id: 'faq',
			icon: '#icon-list-ol',
			menutitle: locale['FAQ']['MENUTITLE'],
			route: '/faq',
			permissions: ['mobile_admin', 'mobile_superuser'],
			childrens: [
				{
					menutitle: locale['FAQ_ANSWERS']['MENUTITLE'],
					route: '/faq/answers/',
				},
			],
		},
		{
			thread_id: 'games',
			icon: '#icon-gamepad-modern',
			menutitle: locale['GAMES']['MENUTITLE'],
			route: '/games',
			permissions: ['mobile_admin', 'mobile_superuser'],
			childrens: [
				// {
				// 	menutitle: locale['GAMES_LEVEL_MECHANICS']['MENUTITLE'],
				// 	route: '/games/level-mechanics/',
				// },
				// {
				// 	menutitle: locale['GAMES_AR_CONFIGS']['MENUTITLE'],
				// 	route: '/games/ar-configs/',
				// },
				{
					menutitle: locale['GAMES_LEVELS']['MENUTITLE'],
					route: '/games/levels/',
				},
				{
					menutitle: locale['GAMES_QUESTS']['MENUTITLE'],
					route: '/games/quests/',
				},
				// {
				// 	menutitle: locale['GAMES_INSTRUCTIONS']['MENUTITLE'],
				// 	route: '/games/instructions/',
				// },
				// {
				// 	menutitle: locale['GAMES_CONFIGS']['MENUTITLE'],
				// 	route: '/games/configs/',
				// },
			],
		},
		/*{
			thread_id: 'qr-codes',
			icon: '#icon-qrcode',
			menutitle: locale['QR_CODES']['MENUTITLE'],
			route: '/qr-codes',
			permissions: ['mobile_admin', 'mobile_superuser'],
		},*/
		{
			thread_id: 'map',
			icon: '#icon-location-dot',
			menutitle: locale['MAP']['MENUTITLE'],
			route: '/map',
			permissions: ['mobile_admin', 'mobile_superuser'],
			childrens: [
				{
					menutitle: locale['AUDIO_GUIDES']['MENUTITLE'],
					route: '/map/audio-guides/',
				},
				/*
				{
					menutitle: locale['ROUTE_POINTS']['MENUTITLE'],
					route: '/map/route-points/',
				},
				{
					menutitle: locale['MAP_ROUTES']['MENUTITLE'],
					route: '/map/routes/',
				},
				*/
				{
					menutitle: locale['MAP_CATEGORIES']['MENUTITLE'],
					route: '/map/categories/',
				},
				{
					menutitle: locale['COMPANIES']['MENUTITLE'],
					route: '/map/companies/',
				},
			],
		},
		/*{
			thread_id: 'market',
			icon: '#icon-store',
			menutitle: locale['MARKET']['MENUTITLE'],
			route: '/market',
			childrens: [
				{
					menutitle: locale['MARKET_MEMBERS']['MENUTITLE'],
					route: '/market/members/',
				},
				{
					menutitle: locale['MARKET_CATEGORIES']['MENUTITLE'],
					route: '/market/categories/',
				},
			],
		},*/
		{
			thread_id: 'souvenirs',
			icon: '#icon-bag-shopping',
			menutitle: locale['SOUVENIRS']['MENUTITLE'],
			route: '/souvenirs',
			permissions: ['mobile_admin', 'mobile_superuser', 'mobile_seller'],
			childrens: [
				{
					menutitle: locale['SOUVENIRS_ORDERS']['ARCHIVED_MENUTITLE'],
					route: '/souvenirs/orders/archived/',
				},
				{
					menutitle: locale['SOUVENIRS_ORDERS']['MENUTITLE'],
					route: '/souvenirs/orders/',
				},
				{
					menutitle: locale['SOUVENIRS_PRODUCTS']['MENUTITLE'],
					route: '/souvenirs/categories/',
					permissions: ['mobile_admin', 'mobile_superuser'],
				},
				{
					menutitle: locale['SOUVENIRS_BRANDS']['MENUTITLE'],
					route: '/souvenirs/brands/',
					permissions: ['mobile_admin', 'mobile_superuser'],
				},
				// {
				// 	menutitle: locale['SOUVENIRS_CATEGORIES']['MENUTITLE'],
				// 	route: '/souvenirs/categories/',
				// 	permissions: ['mobile_admin', 'mobile_superuser'],
				// },
			],
		},
		{
			thread_id: 'schedule',
			menutitle: locale['SCHEDULE']['TITLE'],
			route: '/schedule',
			permissions: ['mobile_admin', 'mobile_superuser'],
		},
		{
			thread_id: 'newsline',
			icon: '#icon-newspaper',
			menutitle: locale['NEWSLINE']['MENUTITLE'],
			route: '/newsline',
			permissions: ['mobile_admin', 'mobile_superuser'],
			childrens: [
				{
					menutitle: locale['NEWSLINE_VIDEOS']['MENUTITLE'],
					route: '/newsline/videos/',
				},
				{
					menutitle: locale['NEWSLINE_ARTICLES']['MENUTITLE'],
					route: '/newsline/articles/',
				},
				{
					menutitle: locale['NEWSLINE_EVENTS']['MENUTITLE'],
					route: '/newsline/events/',
				},
			],
		},
		{
			thread_id: 'users',
			icon: '#icon-users',
			menutitle: locale['USERS']['MENUTITLE'],
			route: '/users',
			permissions: ['mobile_admin', 'mobile_superuser'],
			childrens: [
				/*
				{
					menutitle: locale['USERS_REVIEWS']['MENUTITLE'],
					route: '/users/reviews/',
				},
				*/
				{
					menutitle: locale['USERS_RATING']['MENUTITLE'],
					route: '/users/rating/',
				},
				/*
				{
					menutitle: locale['USERS_ACHIEVEMENTS_HISTORY']['MENUTITLE'],
					route: '/users/achievements-history/',
				},
				*/
				// {
				// 	menutitle: locale['USERS_TRANSACTIONS_HISTORY']['MENUTITLE'],
				// 	route: '/users/transactions-history/',
				// },
				// {
				// 	menutitle: locale['USERS_RATING_HISTORY']['MENUTITLE'],
				// 	route: '/users/rating-history/',
				// },
				{
					menutitle: locale['LOGS']['MENUTITLE'],
					route: '/users/logs/',
				},
				{
					menutitle: locale['USERS_MANAGERS']['MENUTITLE'],
					route: '/users/managers/',
				},
				{
					menutitle: locale['USERS_APP']['MENUTITLE'],
					route: '/users/app/',
				},
			],
		},
	] as MobileMenuNavigationItemInterface[]

	const toggleThreadHandler = (thread_id: string) => {
		const openedThreads = app['aside']['opened']

		if (openedThreads.includes(thread_id)) {
			openedThreads.splice(openedThreads.indexOf(thread_id), 1)
		} else {
			openedThreads.push(thread_id)
		}

		setCookie('aside.opened', JSON.stringify(openedThreads), {
			path: '/',
		})

		setApp((app) => ({
			...app,
			aside: {
				opened: openedThreads,
			},
		}))
	}

	return (
		<>
			<div className="ui-sidebar-header">
				<Link href="/" onClick={() => setOpen(null)} className="tw-inline-flex tw-items-center tw-gap-x-[20px]">
					<Icon className="tw-h-[40px] tw-w-[192px] tw-flex-shrink-0 tw-text-white" name="#icon-app-logo" />
				</Link>

				<button type="button" onClick={() => setOpen(null)} className="ui-sidebar-button-close">
					<Icon name="#icon-xmark" />
				</button>
			</div>
			{user?.['profile'] && (
				<div className="tw-flex tw-flex-col tw-divide-y tw-divide-disabled tw-bg-white">
					{user['profile']?.['tenant']?.['display_name'] && <div className="ui-h4 tw-px-[36px] tw-py-[24px]">{user['profile']['tenant']['display_name']}</div>}

					<div className="tw-flex tw-items-center tw-justify-between tw-gap-x-[36px] tw-px-[36px] tw-py-[24px]">
						<Link href={'/users/managers/' + user['profile']['uuid']} onClick={() => setOpen(null)} className="tw-flex tw-flex-col tw-gap-y-[4px] tw-leading-none">
							<div className="tw-font-medium">{user['profile']['name']}</div>
							<div className="tw-text-[14px] tw-text-secondary-on-surface">{user['profile']['email']}</div>
						</Link>

						<button
							type="button"
							onClick={async () => {
								await logout()
							}}
						>
							<Icon name="#icon-arrow-right-from-bracket" className="tw-h-[20px] tw-w-[20px]" />
						</button>
					</div>
				</div>
			)}
			<div className="ui-sidebar-body">
				<div className="ui-sidebar-content">
					<div className="ui-app-mobile-menu">
						<ul className="ui-app-mobile-menu-items">
							{navigation.map((item) => (
								<Fragment key={item['menutitle']}>
									{(!item['permissions'] ||
										item['permissions'].some((key) => userPermissions.includes(key) && !APP_PERMISSIONS_ACCESSES.NOT_ALLOWED.includes(userPermissions[key]))) && (
										<MobileMenuSidebar.ListItem
											data={item}
											level={0}
											setOpen={setOpen}
											openedThreads={app['aside']['opened']}
											onThreadToggle={toggleThreadHandler}
											userPermissions={userPermissions}
										/>
									)}
								</Fragment>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

const ListItem = ({data, level = 0, openedThreads = [], onThreadToggle, setOpen, userPermissions, ...props}: MobileMenuListItemProps) => {
	const router = useRouter()

	return (
		<li
			className={
				'ui-app-mobile-menu-item' +
				(data['route'] && router['asPath'].startsWith(data['route']) && (!data?.['thread_id'] || !openedThreads.includes(data['thread_id'])) ? ' ui-app-mobile-menu-item-active' : '')
			}
		>
			{data?.['route'] && !data?.['childrens'] && (
				<Link href={data['route']} onClick={() => setOpen(null)} className={'ui-app-mobile-menu-link' + (data?.['className'] ? ' ' + data['className'] : '')} {...props}>
					<>
						<div className="tw-h-[22px] tw-w-[22px] tw-flex-shrink-0">{data?.['icon'] && <Icon className="tw-h-full tw-w-full" name={data['icon']} />}</div>

						<span className="tw-text-left">{data['menutitle']}</span>
					</>
				</Link>
			)}

			{data?.['onClick'] && (
				<button type="button" onClick={data['onClick']} className={'ui-app-mobile-menu-link' + (data?.['className'] ? ' ' + data['className'] : '')} {...props}>
					<div className="tw-h-[22px] tw-w-[22px] tw-flex-shrink-0">{data?.['icon'] && <Icon className="tw-h-full tw-w-full" name={data['icon']} />}</div>

					<span className="tw-text-left">{data['menutitle']}</span>
				</button>
			)}

			{data?.['thread_id'] && data?.['childrens'] && (
				<>
					<button type="button" onClick={() => onThreadToggle(data['thread_id'])} className={'ui-app-mobile-menu-link' + (data?.['className'] ? ' ' + data['className'] : '')} {...props}>
						<div className="tw-h-[22px] tw-w-[22px] tw-flex-shrink-0">{data?.['icon'] && <Icon className="tw-h-full tw-w-full" name={data['icon']} />}</div>

						<span className="tw-text-left">{data['menutitle']}</span>
					</button>

					{openedThreads.includes(data['thread_id']) && (
						<ul className="ui-app-mobile-menu-items">
							{data['childrens'].map(
								(children) =>
									(!children['permissions'] ||
										children['permissions'].some((key) => userPermissions.includes(key) && !APP_PERMISSIONS_ACCESSES.NOT_ALLOWED.includes(userPermissions[key]))) && (
										<MobileMenuSidebar.ListItem
											key={children['menutitle']}
											data={children}
											level={level + 1}
											setOpen={setOpen}
											openedThreads={openedThreads}
											onThreadToggle={onThreadToggle}
											userPermissions={userPermissions}
										/>
									)
							)}
						</ul>
					)}
				</>
			)}
		</li>
	)
}
MobileMenuSidebar.ListItem = ListItem

export default memo(MobileMenuSidebar)
