import {atom} from 'jotai'

import {ManagerInterface} from '~types/managers'

interface UserStateInterface {
	profile: ManagerInterface
	device: string
}

export const UserState = atom<UserStateInterface>({
	profile: null,
	device: null,
})
